import { View, Text, SafeAreaView, StyleSheet } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useNavigation } from "@react-navigation/native";
import { useSubscription } from "../screens/SubscriptionScreen";
import { AntDesign } from "@expo/vector-icons";
import { url } from "../constant/constants";
import { useAuth } from "../App";
import GoogleEvent from "../utils/meta/googleEvent";
import { useQuiz } from "../useContext/useQuiz";

const PurchaseCard = () => {
  const { selected } = useSubscription();
  const { currentUser } = useAuth();
  const [purchaseFailed, setPurchaseFailed] = useState(false);
  const iframeRef = useRef();
  const { couponDetails } = useQuiz()
  const navigation = useNavigation();

  useEffect(() => {
    const handler = (event) => {

      if (typeof event.data !== "object") return;
      if (!event.type) return;
      if (event.type !== "message") return;
      if (event.data.status === "success") {
        navigation.navigate("SuccessPurchase");
      }
      if (event.data.status === "failed") {
        setPurchaseFailed(true);
      }
      return
    };

    window.addEventListener("message", handler);

    // Don't forget to remove addEventListener
    return () => window.removeEventListener("message", handler);
  }, []);

  if (purchaseFailed) {
    return (
      
      <View className="flex justify-center items-center">
        {" "}
        <h1>
          {" "}
          הרכישה נכשלה אנא נסו שוב 
        </h1>
        <h2>במידה ולא הצלחתם ניתן ליצור קשר עם המספר
          0586519423 בווצאפ</h2>
      </View>
    );
  }
  

  return (
    <SafeAreaView className="flex-1 mt-9 bg-white" style={{ direction: "rtl" }}>
      <GoogleEvent eventName={"start-purchase-web"} details={selected} send_to='AW-804768539/UzYKCKet0-cYEJuW3_8C'  userName={currentUser.email} />
      <View className="justify-center items-center z-40">
        <View
          className="absolute top-10  p-2 bg-white rounded-full"
          style={{ left: 24 }}
        >
          <TouchableOpacity onPress={navigation.goBack}>
            <AntDesign name="arrowleft" size={20} color={"#00ccbb"} />
          </TouchableOpacity>
        </View>
      </View>

      <iframe
        ref={iframeRef}
        style={styles.container}
        // src={`${url.pay}`}
        src={`${url.pay}?ClientId=${currentUser.email}&PlanId=${selected.id}&app=first${!!couponDetails? `&couponId=${couponDetails.couponId}` : "" }`}
        // <iframe src="/test.html" onLoad="alert(this.contentWindow.location);"></iframe>
      />
    </SafeAreaView>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 5,
  },
});

export default PurchaseCard;


